export const environment = {
  apiUrl: 'https://api.sportngin.com/',
  app: 'https://app.sportngin.com/',
  // The following is used by SeFeAttachmentService
  assetService: 'https://attachments.se-assets.com/',
  baseUrl: 'https://people-and-orgs.ui.sportngin.com',
  bossUrl: 'https://boss.ui.sportngin.com',
  clientId: 'f41e1ffa1f6e12a25dcc5169a13e10fd',
  eligibilityListUi: 'https://eligibility-list.ui.sportngin.com',
  hqPath: 'https://org.sportngin.com/org',
  local: false,
  membershipService: 'https://membership-service.sportsengine.com',
  mySE: 'https://my.sportngin.com/user',
  name: 'production',
  nbcUniversal: 'https://www.nbcuniversal.com',
  nginUserDashboard: 'https://login.sportngin.com/',
  production: true,
  recaptchaV2SiteKey: '6LcIxy4UAAAAANuWSG9dSmM1UhZNoWYGU0tdrmYF',
  seBar: 'https://se-bar.sportngin.com/se-bar.js',
  seFeAngularServices: 'https://se-fe-angular-services.ui.sportngin.com',
  sePayments: 'https://se-payments.sportngin.com/',
  siteRedirect: 'https://admin.sportngin.com/site_admin/org_redirect',
  sportAdminElements: 'https://sport-admin-elements.ui.sportngin.com',
  staging: false,
  uiThemes: 'https://s3.amazonaws.com/sportngin-snap-production/ui_themes',
  user: 'https://user.sportsengine.com/',
  webCal: 'webcal://ical.sportngin.com/v3/calendar/ical',
  orderHistory: 'https://order-history.ui.sportsengine.com',
  returnUrls: {
    memberships: 'https://memberships.sportsengine.com/'
  },
  domainEnvironments: [
    {
      domain: 'sportngin.com',
      default: true,
      urls: {
        api: 'https://api.sportngin.com',
        userService: 'https://user.sportngin.com/',
      }
    },
    {
      domain: 'sportsengine.com',
      default: false,
      urls: {
        api: 'https://se-api.sportsengine.com',
        userService: 'https://user.sportsengine.com/',
      }
    }
  ],
  // The following is for integration with se-fe-auth-support
  domainSpecificUrls: [
    {
      domain: 'sportngin.com',
      default: true,
      api: 'https://api.sportngin.com/',
      user: 'https://user.sportngin.com/'
    },
    {
      domain: 'sportsengine.com',
      default: false,
      api: 'https://se-api.sportsengine.com/',
      user: 'https://user.sportsengine.com/'
    }
  ]
}
